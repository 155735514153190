import {ClubListScreen} from '@src/features/club/screens/ClubListScreen';
import {UserLayout} from '@src/layouts/UserLayout';
import {GetStaticProps} from 'next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import {useTranslation} from 'next-i18next';

function IndexPage() {
  const {t} = useTranslation();
  return (
    <>
      <Head>
        <title>{t('pages:pick-club')}</title>
      </Head>
      <UserLayout>
        <ClubListScreen />
      </UserLayout>
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({locale}) => {
  if (!locale) {
    throw new Error('locale is undefined');
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'auth',
        'pages',
        'common',
        'user-profile',
        'my-profile',
        'configuration',
      ])),
    },
  };
};

IndexPage.auth = {
  role: 'USER',
};

export default IndexPage;
